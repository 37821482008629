export const ActionTypes = {
  //USER ACTIONTYPES
  GET_USERS: 'GET_USERS',
  GET_ACTIVE_USERS: 'GET_ACTIVE_USERS',
  GET_USER: 'GET_USER',
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  GET_ROLES: 'GET_ROLES',
  GET_SPECIFIC_USERS_BY_ROLE: 'GET_SPECIFIC_USERS_BY_ROLE',

  GET_CUSTOMERS: ' GET_CUSTOMERS',
  GET_CUSTOMERS_BY_ID: ' GET_CUSTOMERS_BY_ID',
  GET_CUSTOMER: ' GET_CUSTOMER',
  GET_EXTERNAL_USERS: 'GET_EXTERNAL_USERS',
  GET_ALL_JOB_TITLE: 'GET_ALL_JOB_TITLE',
  GET_ALL_USER_TYPES: 'GET_ALL_USER_TYPES',
  GET_TIME: 'GET_TIME',
  GET_DASHBOARD_REPORT: 'GET_DASHBOARD_REPORT',

  GET_SUPPLIERS: 'GET_SUPPLIERS',
  GET_SUPPLIER: 'GET_SUPPLIER',
  GET_SUPPLIER_NUMBERS: 'GET_SUPPLIER_NUMBERS',
  GET_EXTERNAL_SUPPLIERS: 'GET_EXTERNAL_SUPPLIERS',
  GET_EXTERNAL_SUPPLIER_DASHBOARD: 'GET_EXTERNAL_SUPPLIER_DASHBOARD',
  GET_SUPPLIER_TABLE_DATA: 'GET_SUPPLIER_TABLE_DATA',
  GET_SUPPLIERS_PORTAL: 'GET_SUPPLIERS_PORTAL',
  GET_PO_SALES_TABLE_DATA: 'GET_PO_SALES_TABLE_DATA',
  GET_SUPPLIER_PO_EXCEL_DATA: 'GET_SUPPLIER_EXCEL_DATA',
  GET_ADD_ORDER_CHANGE_REQUEST: 'GET_ADD_ORDER_CHANGE_REQUEST',
  GET_PO_CHANGE_REQUEST_DATA: 'GET_PO_CHANGE_REQUEST_DATA',
  GET_PO_CHANGE_REQUEST_EXCEL_DATA: 'GET_PO_CHANGE_REQUEST_EXCEL_DATA',
  APPROVE_PO_CHANGE_REQUEST_DATA: 'APPROVE_PO_CHANGE_REQUEST_DATA',
  REJECT_PO_CHANGE_REQUEST_DATA: 'REJECT_PO_CHANGE_REQUEST_DATA',
  GET_BULK_PO_CHANGE_REQUEST_UPLOAD_HISTORY: 'GET_BULK_PO_CHANGE_REQUEST_UPLOAD_HISTORY',
  GET_BULK_PO_CHANGE_REQUEST_CONFIG: 'GET_BULK_PO_CHANGE_REQUEST_CONFIG',
  GET_BULK_PO_CHANGE_REQUEST_EXCEL_DATA: 'GET_BULK_PO_CHANGE_REQUEST_EXCEL_DATA',
  GET_PO_READY_SHIP_DATA: 'GET_PO_READY_SHIP_DATA',
  GET_PO_READY_SHIP_EXCEL_DATA: 'GET_PO_READY_SHIP_EXCEL_DATA',
  GET_READY_SHIP_CONFIG: 'GET_READY_SHIP_CONFIG',
  GET_READY_SHIP_UPLOAD_HISTORY: 'GET_READY_SHIP_UPLOAD_HISTORY',

  GET_FEATURES: 'GET_FEATURES',
  GET_CUSTOMER_TABLE_DATA: 'GET_CUSTOMER_TABLE_DATA',
  GET_BRANCH_DATA: 'GET_BRANCH_DATA',

  GET_CUSTOMER_NUMBERS: 'GET_CUSTOMER_NUMBERS',
  GET_BULK_ORDER_DATA: 'GET_BULK_ORDER_DATA',

  GET_BULK_ORDER_UPLOAD_HISTORY: 'GET_BULK_ORDER_UPLOAD_HISTORY',

  GET_ALL_UNASSOCIATED_CUSTOMERS: 'GET_ALL_UNASSOCIATED_CUSTOMERS',
  GET_BLANKET_PO_DATA: 'GET_BLANKET_PO_DATA',
  GET_FORECAST_DATA: 'GET_FORECAST_DATA',

  GET_RESEND_EMAIL: 'GET_RESEND_EMAIL',

  GET_INTERNAL_USERS: 'GET_INTERNAL_USERS',

  AUTHENTICATE_USER: 'AUTHENTICATE_USER',
  GET_AUTH_USER: 'GET_AUTH_USER',
  GET_VERIFY_USER: 'GET_VERIFY_USER',
  LOGOUT: 'LOGOUT',

  GET_BPO_EXCEL_DATA: 'GET_BPO_EXCEL_DATA',
  GET_FORECAST_EXCEL_DATA: 'GET_FORECAST_EXCEL_DATA',
  GET_BULK_EXCEL_DATA: 'GET_BULK_EXCEL_DATA',
  GET_PREFFERED_UOM: 'GET_PREFFERED_UOM',
  GET_BPO_HISTORYID: 'GET_BPO_HISTORYID',
  FETCH_BPO: 'FETCH_BPO',

  GET_LOGO_IMAGE: 'GET_LOGO_IMAGE',
  GET_BULK_HISTORYID: 'GET_BULK_HISTORYID',
  GET_AUTH_USER_DETAIL: 'GET_AUTH_USER_DETAIL',
  GET_EXTERNAL_USER_DASHBOARD: 'GET_EXTERNAL_USER_DASHBOARD',
  GET_CUSTOMERS_PORTAL: 'GET_CUSTOMERS_PORTAL',
  GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
  GET_PREFFERED_TIMEZONE: 'GET_PREFFERED_TIMEZONE',
  GET_DASHBOARD_TABLE_DATA: 'GET_DASHBOARD_TABLE_DATA',
  GET_BARCODE_LIST: 'GET_BARCODE_LIST',
  LOADER_STATUS: 'LOADER_STATUS',
  GET_CUSTOMER_SHIPPING_LOCATION: 'GET_CUSTOMER_SHIPPING_LOCATION',
  GET_CUSTOMER_BLANKET_PO: 'GET_CUSTOMER_BLANKET_PO',
  GET_ALL_CART_ORDERS: 'GET_ALL_CART_ORDERS',
  GET_FORECAST_UPLOAD_HISTORY: 'GET_FORECAST_UPLOAD_HISTORY',
  GET_PRODUCT_DETAILS: 'GET_PRODUCT_DETAILS',
  GET_BRANCH_INVENTORY_DETAILS: 'GET_BRANCH_INVENTORY_DETAILS',
  GET_MK_INVENTORY_DETAILS: 'GET_MK_INVENTORY_DETAILS',
  GET_OPEN_ORDERS_DETAILS: 'GET_OPEN_ORDERS_DETAILS',
  GET_ORDER_HISTORY: 'GET_ORDER_HISTORY',
  GET_CUSTOMER_ORDER_HISTORY: 'GET_CUSTOMER_ORDER_HISTORY',
  GET_FORECAST_COMPARE_DATA: 'GET_FORECAST_COMPARE_DATA',
  GET_CUSTOMER_VIEW_PRODUCTS: 'GET_CUSTOMER_VIEW_PRODUCTS',
  GET_CUSTOMER_DATA_FOR_PORTAL: 'GET_CUSTOMER_DATA_FOR_PORTAL',
  GET_ADD_CART_RESPONSE: 'GET_ADD_CART_RESPONSE',
  CLEAR_CART_FIELD_ERROR: 'CLEAR_CART_FIELD_ERROR',
  PART_NUMBER: 'PART_NUMBER',

  GET_COUNT: 'GET_COUNT',
  FORECAST_COMPARE_ERROR: 'FORECAST_COMPARE_ERROR',
  GET_PART_FORECAST: 'GET_PART_FORECAST',
  SELECTED_PRODUCT_DATA: 'SELECTED_PRODUCT_DATA',
  GET_BARCODE_ERRORS: 'GET_BARCODE_ERRORS',
  GET_BARCODE_PROCESS_ERRORS: 'GET_BARCODE_PROCESS_ERRORS',
  GET_FORECAST_HISTORYID: 'GET_FORECAST_HISTORYID',
  GET_MENU_ACTIVE: 'GET_MENU_ACTIVE',
  GET_PRODUCTS_CSV_DATA: 'GET_PRODUCTS_CSV_DATA',
  GET_CUSTOMER_ORDER_HISTORY_CSV: 'GET_CUSTOMER_ORDER_HISTORY_CSV',
  GET_ORDER_HISTORY_EXCEL: 'GET_ORDER_HISTORY_EXCEL',

  GET_ALL_PERMISSIONS: 'GET_ALL_PERMISSIONS',

  RAW_MATERIAL_FETCH_SUCCESS: 'RAW_MATERIAL_FETCH_SUCCESS',
  RAW_MATERIAL_FETCH_ERROR: 'RAW_MATERIAL_FETCH_ERROR',
  FINISHED_GOODS_FETCH_SUCCESS: 'FINISHED_GOODS_FETCH_SUCCESS',
  RAW_MATERIAL_GROUPED_FETCH_SUCCESS: 'RAW_MATERIAL_GROUPED_FETCH_SUCCESS',
  RAW_MATERIAL_GROUPED_FETCH_ERROR: 'RAW_MATERIAL_GROUPED_FETCH_ERROR',
  GET_RAW_MATERIAL_VIEW: 'GET_RAW_MATERIAL_VIEW',
  CLEAR_DATA: 'CLEAR_DATA',
};
